/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Subtitle, Menu, MenuButton, MenuWrapBox, Button, MenuButtonClose, Image, Text, Fullmap } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"English"} description={"MIRA studio - massages, cosmetic care and waxing"}>
        <Column className="css-1c6uie2 --left bg--center pt--80" name={"vb6rsx31h7i"} style={{"paddingTop":null,"marginBottom":0,"paddingBottom":36.09375,"backgroundColor":"rgba(255, 255, 255, 1)"}} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/2871/cbfdcf5193364e5f870e3c5fff7e7f68_s=3000x_.jpg);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--style4 title-box--center ff--3 fs--48 w--500 lh--2" style={{"marginTop":27.693748474121097,"paddingTop":0,"marginBottom":1,"paddingBottom":0}} content={"MIRA studio"}>
              </Title>

              <Subtitle className="subtitle-box ff--3 fs--18" style={{"marginTop":0,"paddingTop":0}} content={"massage &amp; cosmetic care &amp; waxing"}>
              </Subtitle>

              <Subtitle className="subtitle-box subtitle-box--style1 ff--3 fs--14 ls--12" style={{"paddingBottom":30.46875}} content={"<span style=\"font-weight: bold;\">My name is Simona Boháčová and I welcome You on my web page of my relaxation studio.&nbsp;\nYou can find here a complete offer of massages and beauty services, price list, various events, information on customer membership, a link to social networks, contact to the studio and much more!</span>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--menu pb--12 pt--12" style={{"backgroundColor":"rgba(0, 0, 0, 1)"}} menu={true}>
          
          <Menu className="--full" fullscreen={true}>
            
            <MenuButton >
            </MenuButton>

            <MenuWrapBox className="--center">
              
              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 ff--3 fs--20 btn-box--invert" style={{"backgroundColor":"rgba(235,214,139,1)"}} href={"/"} target={""} content={"<span style=\"font-weight: 700; color: var(--black);\">HLAVNÍ STRÁNKA</span>"}>
              </Button>

              <MenuButtonClose >
              </MenuButtonClose>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 ff--3 fs--20 w--400 btn-box--invert" style={{"backgroundColor":"rgba(235,214,139,1)"}} href={"https://mira-studio.cz/masaze"} target={""} content={"<span style=\"font-weight: bold; color: var(--black);\">MASÁŽE</span>"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 ff--3 fs--20 btn-box--invert" style={{"backgroundColor":"rgba(235,214,139,1)"}} url={"https://mira-studio.cz/kosmetika"} href={"https://mira-studio.cz/kosmetika"} target={""} content={"<span style=\"font-weight: bold; color: var(--black);\">KOSMETIKA</span>"}>
              </Button>

              <Button className="btn-box btn-box--cbtn2 btn-box--sbtn2 btn-box--style2 ff--3 fs--20 w--400 btn-box--invert" style={{"backgroundColor":"rgba(235,214,139,1)"}} href={"/darkovepukazy"} target={""} content={"<span style=\"font-weight: bold; color: var(--black);\">DÁRKOVÉ POUKAZY</span>"}>
              </Button>

            </MenuWrapBox>

          </Menu>

        </Column>


        <Column name={"uow7u16f1t"} style={{"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":0,"backgroundColor":"var(--color-main-bg-2)"}}>
          
          <ColumnWrap className="column__flex el--2 flex--stretch" style={{"marginTop":0,"paddingTop":0,"marginBottom":0,"paddingBottom":0}} columns={"2"}>
          </ColumnWrap>

        </Column>


        <Column name={"i20zvrchxjl"} style={{"marginTop":0,"paddingTop":2,"paddingBottom":5.462493896484375}} layout={"l16"}>
          
          <ColumnWrap className="column__flex el--2 flex--center" columns={"2"}>
            
            <ColumnWrapper className="--center">
              
              <Image src={"https://cdn.swbpg.com/t/2871/ebda732ec7504662b8e2b20f960a6b4b_s=860x_.jpg"} svg={false} sizes={"(min-width: 40em) 28vw, 100vw"} srcSet={"https://cdn.swbpg.com/t/2871/ebda732ec7504662b8e2b20f960a6b4b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2871/ebda732ec7504662b8e2b20f960a6b4b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2871/ebda732ec7504662b8e2b20f960a6b4b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2871/ebda732ec7504662b8e2b20f960a6b4b_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2871/ebda732ec7504662b8e2b20f960a6b4b_s=2000x_.jpg 2000w"} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box ff--3 fs--14 w--300 mt--06" content={"Keep smiling!<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Title className="title-box title-box--style2 fs--20" content={"<span style=\"font-weight: bold;\">About MIRA studio</span><br>"}>
              </Title>

              <Text className="text-box ff--3 fs--14" content={"&nbsp;It is a dream, my dream and I will be very happy to welcome you in it.<br><br>I really enjoy my job and I am always learning new information, procedures and techniques so that I can provide you with the best possible services!<br><br>I focus on <span style=\"font-weight: bold; text-decoration-line: underline;\">massages</span> that are not only relaxing, but go deep and really help - from pain, from stress, from insomnia, ...<br><br><span style=\"font-weight: bold; text-decoration-line: underline;\">Beauty treatment</span> is truly an experience. I use quality and luxurious Czech cosmetics, modern devices and effective procedures, thanks to which we will put your skin together in the best possible condition.<br><br>\nThe studio is smaller, but modern and cozy, I'm sure you'll feel really great in here!<br><br><span style=\"text-decoration-line: underline;\">Come </span>and try my services - I look forward to seeing you!<br>Simona Boháčová<br><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"41zyde2jwee"} style={{"marginTop":7.75,"paddingTop":18}}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2871/8335dba33c4b4f8d9be83eaddd99c6e6_s=860x_.jpg"} svg={false} ratio={"4:3"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/2871/8335dba33c4b4f8d9be83eaddd99c6e6_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2871/8335dba33c4b4f8d9be83eaddd99c6e6_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2871/8335dba33c4b4f8d9be83eaddd99c6e6_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2871/8335dba33c4b4f8d9be83eaddd99c6e6_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2871/8335dba33c4b4f8d9be83eaddd99c6e6_s=2000x_.jpg 2000w"} position={{"x":"-6.48148148148148%","y":"-18.518518518518515%"}}>
              </Image>

              <Title className="title-box" content={"Massages:"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={"LOMI-LOMI (Hawaiian technique)<br>90 min. / 880,-<br><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2871/6824e2f2875d477e807379f24eafc5f7_s=860x_.png"} svg={false} ratio={"4:3"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/2871/6824e2f2875d477e807379f24eafc5f7_s=350x_.png 350w, https://cdn.swbpg.com/t/2871/6824e2f2875d477e807379f24eafc5f7_s=660x_.png 660w, https://cdn.swbpg.com/t/2871/6824e2f2875d477e807379f24eafc5f7_s=860x_.png 860w, https://cdn.swbpg.com/t/2871/6824e2f2875d477e807379f24eafc5f7_s=1400x_.png 1400w, https://cdn.swbpg.com/t/2871/6824e2f2875d477e807379f24eafc5f7_s=2000x_.png 2000w"} position={null}>
              </Image>

              <Title className="title-box" content={"Cosmetics:"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2871/8507fa813f9f4daba6d283e4e13f2ae2_s=350x_.jpg"} svg={false} ratio={"4:3"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":655}} srcSet={"https://cdn.swbpg.com/t/2871/8507fa813f9f4daba6d283e4e13f2ae2_s=350x_.jpg 350w"} position={{"x":"-0.30864197530864196%","y":"-91.35802469135804%"}}>
              </Image>

              <Title className="title-box" content={"Waxing:"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":467}} content={"Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"kqao2p118sb"} style={{"marginTop":0,"paddingTop":0,"paddingBottom":6.98126220703125}} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 fs--20" style={{"marginBottom":0,"paddingBottom":3}} content={"<span style=\"font-weight: bold;\">Poprvé k nám na masáž nebo kosmetiku?</span>"}>
              </Title>

              <Text className="text-box ff--3 fs--14 ls--01" style={{"marginTop":20.381256103515625,"paddingTop":0}} content={"Vzhledem ke zvýšeným hygienickým opatřením v rámci situace<span style=\"font-weight: bold;\"> COVID19</span> Vás prosím, aby jste se dostavili <span style=\"font-weight: bold;\">pokud možno vždy přesně na čas</span>, aby se v čekárně nepotkávalo více klientů. Mezi klienty budu mít nově vždy 15min čas, abych stihla řádně vyvětrat, vše vydezinfikovat a vyměnit jednorázové pomůcky a všechny ručníky. Děkuji za pochopení.<br>&nbsp;<br><span style=\"text-decoration-line: underline; font-weight: bold;\">Upozornění:&nbsp;</span><br>Před masážemi nejíst alespoň 2 hodiny.<br><br><span style=\"font-weight: 700; text-decoration-line: underline;\">Masáž NIKDY neprovádím, jestliže klient má následující zdravotní obtíže:<br></span>zhoubné nádory a zhoubná mateřská znaménka, horečku, hnisavá kožní onemocnění, poruchy krvácivosti, záněty ledvin, tuberkulózu, kožní hnisavá nebo nebo infekční onemocnění, záněty žil, trombózu, křečové žíly, akutní záněty a akutní úrazy, problémy se srdcem<br><span style=\"font-weight: bold;\">Dále nemasíruji ženy těhotné a v šestinedělí.</span><br>Na masáž není vhodné chodit, jestliže máte právě menstruaci.<br><br><span style=\"font-style: italic;\">Jestliže máte pochybnosti, zda je pro Vás masáž vhodná, neváhejte mě kontaktovat. </span><br><span style=\"font-style: italic;\">&nbsp;Poradím Vám ve Vašem nejlepším zájmu - kvalitní péče o zákazníky je moje priorita.</span><br><br>Platba zatím pouze hotovostí.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"82ea6dly32q"} style={{"marginTop":0,"paddingTop":4,"paddingBottom":6.6937408447265625}} layout={"l9"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2871/3c1c7a2ad1bf48f99833720ffad8c28e_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":325}} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2871/3c1c7a2ad1bf48f99833720ffad8c28e_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2871/3c1c7a2ad1bf48f99833720ffad8c28e_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2871/3c1c7a2ad1bf48f99833720ffad8c28e_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2871/3c1c7a2ad1bf48f99833720ffad8c28e_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2871/3c1c7a2ad1bf48f99833720ffad8c28e_s=2000x_.jpg 2000w"} position={{"x":"-54.320987654320966%","y":"0%"}} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center ff--3 fs--16 w--500 mt--06" content={"Čistě povlečené lehátko a načechrané ručníky jsou pro Vaše pohodlí zásadní!"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2871/ca287a608eca4553bc31ad84117bce3b_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2871/ca287a608eca4553bc31ad84117bce3b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2871/ca287a608eca4553bc31ad84117bce3b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2871/ca287a608eca4553bc31ad84117bce3b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2871/ca287a608eca4553bc31ad84117bce3b_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2871/ca287a608eca4553bc31ad84117bce3b_s=2000x_.jpg 2000w"} position={{"x":"-31.790123456790123%","y":"0%"}} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center ff--3 fs--16 w--500 mt--06" content={"Na detailech mi záleží!"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/2871/c563894f85f64cab9dd8022cec0dde60_s=860x_.jpg"} svg={false} ratio={"1:1"} sizes={"(min-width: 40em) 28vw, 100vw"} WrapIn={"square"} srcSet={"https://cdn.swbpg.com/t/2871/c563894f85f64cab9dd8022cec0dde60_s=350x_.jpg 350w, https://cdn.swbpg.com/t/2871/c563894f85f64cab9dd8022cec0dde60_s=660x_.jpg 660w, https://cdn.swbpg.com/t/2871/c563894f85f64cab9dd8022cec0dde60_s=860x_.jpg 860w, https://cdn.swbpg.com/t/2871/c563894f85f64cab9dd8022cec0dde60_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/2871/c563894f85f64cab9dd8022cec0dde60_s=2000x_.jpg 2000w"} position={{"x":"-49.69135802469135%","y":"-53.39506172839505%"}} RootClassName={"column__pic"}>
              </Image>

              <Text className="text-box text-box--center ff--3 fs--16 w--500 mt--06" content={"Vždy pro Vás mám přichystaný džbán s čerstvou vodou!"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"v2lxtrsj83h"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(0, 0, 0, 1)"}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box ff--3 fs--36 w--500 title-box--invert" style={{"paddingTop":0}} content={"WHERE CAN YOU FIND US AND HOW TO CONTACT US?"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--invert" name={"97kd8e0pha"} style={{"paddingTop":34,"marginBottom":0,"paddingBottom":21.681243896484375,"backgroundColor":"var(--color-main-bg-2)"}}>
          
          <ColumnWrap className="column__flex el--4 flex--top" columns={"4"}>
            
            <ColumnWrapper className="--left">
              
              <Image src={"https://cdn.swbpg.com/t/2871/b44fb0dcf304475ea6756fccc642276c_s=350x_.jpg"} svg={false} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" style={{"maxWidth":181,"marginBottom":0,"paddingBottom":4.5}} srcSet={"https://cdn.swbpg.com/t/2871/b44fb0dcf304475ea6756fccc642276c_s=350x_.jpg 350w"} RootClassName={"column__pic"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box ff--3 fs--24 title-box--invert" content={"MIRA studio&nbsp;"}>
              </Title>

              <Text className="text-box ff--3 fs--16 w--500 text-box--invert mt--10" style={{"maxWidth":400}} content={"IČO: 03616568<br><br>contact person:&nbsp;<br><span style=\"font-style: italic;\">Simona Boháčová</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box subtitle-box--style4 subtitle-box--left ff--3 fs--16 subtitle-box--invert" content={"Contact:"}>
              </Subtitle>

              <Text className="text-box text-box--left ff--3 fs--18 w--500 text-box--invert mt--02" content={"+420 736 150 609"}>
              </Text>

              <Text className="text-box ff--3 fs--16 w--500 text-box--invert mt--0 pt--0" content={"facebook: @mirastudiobrno<br>"}>
              </Text>

              <Button className="btn-box btn-box--style2 btn-box--invert" href={"https://www.facebook.com/mirastudiobrno/"} content={"FACEBOOK"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Subtitle className="subtitle-box subtitle-box--style4 ff--3 fs--16 subtitle-box--invert" content={"Address:"}>
              </Subtitle>

              <Text className="text-box ff--3 fs--18 w--500 text-box--invert mt--02" content={"Kounicova 75, <br>Brno 602 00"}>
              </Text>

              <Text className="text-box text-box--left ff--3 fs--13 w--300 text-box--invert mt--0 pt--0" content={"<span style=\"font-style: italic;\">From Main Train station by tram no. 12, direction Technologický Park, Klusáčkova stop - approx. 5 min from the city center of Brno and tram stop Česká.</span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"v2lxtrsj83h"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(0, 0, 0, 1)"}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Title className="title-box ff--3 fs--36 w--500 title-box--invert" style={{"paddingTop":0}} content={"Google maps:"}>
              </Title>

              <Button className="btn-box btn-box--style2 btn-box--invert" href={"https://www.google.cz/maps/place/MIRA+studio+-+masáže+a+relaxace/@49.2113373,16.5891307,16.22z/data=!4m5!3m4!1s0x47733399a837f975:0xe8cf48225afd9e4b!8m2!3d49.2113978!4d16.5920673"} content={"HERE"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"v2lxtrsj83h"} style={{"paddingTop":0,"paddingBottom":0,"backgroundColor":"rgba(0, 0, 0, 1)"}}>
          
          <ColumnWrap className="column__flex el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Fullmap name={"enqpd7znz0j"}>
        </Fullmap>

      </Layout>
    </ThemeWrapper>
  )
}